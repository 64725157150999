const config = {
    host: (process.env.NODE_ENV === 'production') ? 'https://viagu.dvgeo.app' : 'http://192.168.100.106:3057',
    homePage: (process.env.NODE_ENV === 'production') ? 'https://viagu.dvgeo.app' : 'http://192.168.100.106:3057',
    api: 'api/',
    apiSocket: 'viagu::1.2',
    nameDir: 'viagu',
    package: 'app.dvgeo.viagu.web',
    version: '1.0.0',
    googleAPIKey: 'AIzaSyCdbIv2e1miWZNoU5FC0NwcRIDRmcWSDF8',
    appName: 'Viagu',
    provider: 'Devjos CIA LTDA',
    colorPrimary: '#00D8D2',
    colorDark: '#000000',
    colorAccent: '#ccc',
    colorTextPrimary: '#fff',
    colorTextDark: '#000',
    playStorePassengerId: 'app.dvgeo.viagu.passenger',
    playStoreDriverId: 'app.dvgeo.viagu.driver',
    appStorePassengerId: '',
    appStoreDriverId: '',
    email: "Italsa.recargas@hotmail.com",
};
export default config;
